<template>
  <div class="department">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="管理员手机号">
            <el-input v-model="search.username" maxlength="30" placeholder="请输入管理员手机号" />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="addBox">
      <el-button @click="handleOpenDia('add')" type="primary" size="mini" class="mb20">新增管理员</el-button>
    </div>

    <el-table
      :data="tableData"
      size="small"
      border
      :cell-style="$style.cellStyle"
      :header-cell-style="$style.rowClass"
    >
      <el-table-column prop="realname" label="管理员名称"></el-table-column>
      <el-table-column prop="username" label="管理员账号"></el-table-column>
      <el-table-column prop="structureName" label="部门名称"></el-table-column>
      <el-table-column prop="groupName" label="角色名称"></el-table-column>
      <el-table-column prop="createTime" label="添加时间"></el-table-column>
      <el-table-column prop="createTime" label="禁用状态">
        <template v-slot="{ row }">
          <el-tag
            size="mini"
            :type="row.status == '0' ? 'danger' : 'success'"
            disable-transitions
          >{{row.status == 0?'已禁用':'已启用'}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="200px">
        <template v-slot="{ row }">
          <el-button type="text" @click="handleOpenDia( row )">编辑</el-button>
          <el-button
            class="ml20"
            :class="row.status == 0?'':'peril'"
            type="text"
            @click="hint( '更改此管理员禁用状态',row )"
          >{{row.status == 0?'启用':'禁用'}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="page tc mt10"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.limit"
      @pagination="getList"
    />

    <el-dialog
      :title="title"
      width="30%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="departmentForm" :rules="rules" label-width="100px" ref="departmentForm">
        <el-form-item label="管理员名称" prop="realname">
          <el-input v-model="departmentForm.realname" placeholder="请输入管理员名称"></el-input>
        </el-form-item>
        <el-form-item label="所在部门" prop="structureId">
          <el-select
            v-model="departmentForm.structureId"
            clearable
            class="w100B"
            placeholder="请选择所在部门"
            @change="changeStructure"
          >
            <el-option
              v-for="item in options.structureId"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="角色名称" prop="groupId">
          <el-select
            v-model="departmentForm.groupId"
            clearable
            class="w100B"
            placeholder="请选择角色"
            @change="changeGroup"
          >
            <el-option
              v-for="item in options.groupId"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="账号名称" prop="username">
          <el-input v-model="departmentForm.username" placeholder="请输入管理员账号"></el-input>
        </el-form-item>
        <el-form-item label="管理员密码">
          <el-input show-password v-model="departmentForm.password" placeholder="请输入管理员密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import {
  readAdminUserList,
  createAdminUserResource,
  updateAdminUserResource,
  readDepartmentList,
  roleList,
  updateStatus
} from "@/api/permission";
const ADD_TITLE = "新增管理员";
const EDIT_TITLE = "编辑管理员";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  username = ""; // 名称
}
class Rules {
  realname = [{ required: true, message: "请填写管理员名称", trigger: "blur" }];
  structureId = [
    { required: true, message: "请选择所在部门", trigger: "change" }
  ];
  groupId = [{ required: true, message: "请选择角色名称", trigger: "change" }];
  username = [{ required: true, message: "请填写账号名称", trigger: "blur" }];
  password = [{ required: true, message: "请填写管理员密码", trigger: "blur" }];
}
class Options {
  structureId = [];
  groupId = [];
}
export default {
  name: "Manage",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), //搜索
      page: new Page(), // 分页
      options: new Options(), // 选项
      rules: new Rules(),
      title: ADD_TITLE,
      dialogVisible: false,
      tableData: [],
      departmentForm: {
        realname: "",
        structureId: "",
        structureName: "",
        groupId: "",
        groupName: "",
        username: "",
        password: ""
      }
    };
  },
  mounted() {
    this.getList();
    this.getOptions();
  },
  methods: {
    // 获取选项
    getOptions() {
      readDepartmentList({ page: 1, limit: 1000000 }).then(res => {
        this.options.structureId = res.data.list;
      });
      roleList().then(res => {
        this.options.groupId = res;
      });
    },
    getList(option) {
      if (option === "search") this.page = new Page();
      readAdminUserList({ ...this.search, ...this.page }).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.dataCount;
      });
    },
    // 选择部门
    changeStructure(id) {
      this.options.structureId.forEach(item => {
        if (id == item.id) {
          this.departmentForm.structureName = item.name;
        }
      });
    },
    // 选择角色
    changeGroup(id) {
      this.options.groupId.forEach(item => {
        if (id == item.id) {
          this.departmentForm.groupName = item.title;
        }
      });
    },
    // 添加/编辑
    handleOpenDia(row) {
      if (row == "add") {
        this.title = ADD_TITLE;
      } else {
        this.title = EDIT_TITLE;
        this.departmentForm = Object.assign({}, row);
        this.departmentForm.password = "";
      }
      this.dialogVisible = true;
    },
    // 提交
    handleSubmit() {
      this.$refs.departmentForm.validate(valid => {
        if (valid) {
          if (this.title == EDIT_TITLE) {
            this.hint("更新此管理员", "");
          } else {
            this.hint("添加此管理员", "");
          }
        }
      });
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type) {
            this.changestatus(type);
          } else {
            this.confirm();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 改变状态
    changestatus(row) {
      updateStatus({ id: row.id, status: row.status == 1 ? "0" : "1" }).then(
        res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.getList();
        }
      );
    },
    // 确认提交
    confirm() {
      if (this.title == EDIT_TITLE) {
        updateAdminUserResource({ ...this.departmentForm }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.dialogVisible = false;
          this.getList();
        });
      } else {
        createAdminUserResource({ ...this.departmentForm }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.dialogVisible = false;
          this.getList();
        });
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.addBox {
  text-align: right;
}
</style>
